<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="productAddModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="productAddModalLabel2"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h4 class="modal-title" id="productAddModalLabel2">Products</h4>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="selectedProducts = []"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <span class="border-top brs"> </span>
        <div class="modal-body" style="height: 50vh; overflow-y: auto">
          <input
            type="text"
            placeholder="Search"
            class="popin-form-control text-15 font-weight-400 input-otp"
            v-model="search"
          />
          <template v-if="products.length > 0">
            <div
              class="form-check p-0 mt-20"
              v-for="item in filteredProducts"
              :key="item.id"
            >
              <label class="form-check-label m-0 w-100" :for="item.id">
                <div
                  class="text-dark text-dark d-flex justify-content-between h-80 w-full"
                  :class="
                    selectedProducts.includes(item.id)
                      ? 'bg-purple text-white'
                      : ''
                  "
                >
                  <div class="d-flex align-items-center px-3 w-100">
                    <input
                      class="text-15 font-weight-400 mr-24"
                      type="checkbox"
                      :value="item.id"
                      :id="'flexCheckDefault' + item.id"
                      v-model="selectedProducts"
                    />
                    <img
                      :src="item.image"
                      class="rounded m-1"
                      height="70"
                      width="70"
                    />
                    <div
                      class="pl-20 flex-fill font-inter font-weight-400 text-14"
                    >
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </template>
          <template v-else>
            <p class="text-center p-5" v-if="loadingProducts == false">
              No products
            </p>
            <p class="text-center p-5" v-else>Loading...</p>
          </template>
        </div>
        <div @click="sendProducts">
          <CustomButton
            btnName="Send"
            btnColor="#710EC5"
            size="91%"
            margin="22px"
          />
        </div>
      </div>
      <!-- modal-content -->
    </div>
  </div>
  <!-- modal-dialog -->

  <!-- Asset Modal -->
  <div
    class="modal fade"
    id="assetAddModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="assetAddModalLabel2"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h4 class="modal-title" id="assetAddModalLabel2">Assets</h4>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="selectedProducts = []"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <span class="border-top brs"> </span>
        <div class="modal-body" style="height: 50vh; overflow-y: auto">
          <div v-if="assetList.length > 0">
            <button class="btn" @click="showAssets(null)">
              <i class="bi bi-chevron-left"></i> Back
            </button>
          </div>
          <input
            v-else
            type="text"
            placeholder="Search"
            class="popin-form-control text-15 font-weight-400 input-otp"
            v-model="searchForProductsWithAssets"
          />
          <template v-if="productsWithAssets.length > 0">
            <div v-if="assetList.length > 0">
              <div
                class="form-check p-0 mt-20"
                v-for="item in assetList"
                :key="item.id"
              >
                <label class="form-check-label m-0 w-100" :for="item.id">
                  <div
                    class="text-dark text-dark d-flex justify-content-between h-80 w-full"
                    :class="
                      selectedAssets.includes(item.id)
                        ? 'bg-purple text-white'
                        : ''
                    "
                  >
                    <div class="d-flex align-items-center px-3 w-100">
                      <label class="d-flex align-items-center w-100">
                        <input
                          class="text-15 font-weight-400 mr-24"
                          type="checkbox"
                          :value="item.id"
                          :id="'flexCheckDefault' + item.id"
                          v-model="selectedAssets"
                        />
                        <img
                          :src="item.preview"
                          class="rounded m-1"
                          height="48"
                          width="48"
                        />
                        <div
                          class="pl-20 flex-fill font-inter font-weight-400 text-14"
                        >
                          {{ item.name }}
                        </div>
                      </label>
                      <div ms-auto title="View Asset">
                        <a
                          target="_blank"
                          :href="item.url"
                          class="text-decoration-none text-black text-14"
                          >View</a
                        >
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div
              v-else
              class="form-check p-0 mt-20"
              v-for="item in filteredProductsWithAssets"
              :key="item.id"
            >
              <label class="form-check-label m-0 w-100" :for="item.id">
                <div
                  class="text-dark text-dark d-flex justify-content-between h-80 w-full"
                >
                  <div
                    class="d-flex align-items-center px-1 w-100"
                    @click="showAssets(item.id)"
                  >
                    <img
                      :src="item.image"
                      class="rounded m-1"
                      height="70"
                      width="70"
                    />
                    <div
                      class="pl-20 flex-fill font-inter font-weight-400 text-14"
                    >
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </template>
          <template v-else>
            <p class="text-center p-5" v-if="loadingAssets == false">
              No products
            </p>
            <p class="text-center p-5" v-else>Loading...</p>
          </template>
        </div>
        <div v-if="assetList.length > 0" @click="sendAsset">
          <CustomButton
            btnName="Send Asset"
            btnColor="#710EC5"
            size="91%"
            margin="22px"
          />
        </div>
      </div>
      <!-- modal-content -->
    </div>
    <!-- modal-dialog -->
  </div>
  <!-- modal -->
  <div class="card border-0 p-2">
    <div class="form-group mb-0">
      <div class="border-dark border-top border-top-langth"></div>
      <textarea
        class="form-control send-textarea mt-16 pb-0"
        id="message"
        placeholder="Type something...."
        name="message"
        cols="30"
        v-model="selectedUser.message"
        @keydown.enter.prevent="sentMessage()"
        required
      ></textarea>
    </div>
    <div class="row justify-content-end pt-0 text-end p-2">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex">
          <label for="formFile" class="cursor-pointer">
            <i class="bi bi-link-45deg text-28"></i>
            <span
              class="has-file-dot"
              v-if="selectedUser.file || selectedUser.image"
            ></span>
          </label>
          <label
            class="cursor-pointer"
            data-bs-toggle="modal"
            data-bs-target="#productAddModal"
            title="Add Product"
            @click.prevent="sentMessage()"
          >
            <i class="bi bi-plus-lg text-28"></i>
          </label>
          <label
            class="cursor-pointer ms-2"
            data-bs-toggle="modal"
            data-bs-target="#assetAddModal"
            title="Add Asset"
            @click.prevent="sentMessage()"
          >
            <i class="bi bi-collection text-28"></i>
          </label>
        </div>
        <div @click.prevent="sentMessage()">
          <CustomButton btnName="Send" btnColor="#710EC5" />
        </div>
      </div>
      <input
        type="file"
        class="invisible position-absolute"
        id="formFile"
        ref="file"
        accept=".csv, application/pdf, image/jpeg, image/jpg, image/png, image/webp, audio/mpeg, audio/wav, video/mp4, video/x-msvideo, video/quicktime, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        @change="handleFileUpload()"
      />
    </div>
    <p
      v-if="errorMessage.length"
      class="d-block text-truncate text-nowrap w-100 p-0 m-0 text-danger"
      :title="errorMessage"
    >
      {{ errorMessage }}
    </p>
    <p
      v-else
      class="d-block text-truncate text-nowrap w-100 p-0 m-0"
      :title="file_name"
    >
      {{ file_name }}
    </p>
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";
import axios from "axios";
import emitter from "tiny-emitter/instance";
import { Modal } from "bootstrap";
import { computed } from "@vue/runtime-core";
import CustomButton from "@/components/CustomButton.vue";

export default {
  props: ["userId", "from"],
  components: { CustomButton },
  setup() {
    let formData = new FormData();
    const selectedUser = ref({
      user_id: "",
      message: "",
      file: "",
      image: "",
    });
    const selectedProducts = ref([]);

    const loadingProducts = ref(true);
    const products = ref([]);
    const productsWithAssets = ref([]);
    const errorMessage = ref("");
    const loadingAssets = ref(true);
    const assetList = ref([]);
    const selectedAssets = ref([]);

    const filteredProducts = computed(() => {
      return products.value.filter((product) => {
        return product.name.toLowerCase().includes(search.value.toLowerCase());
      });
    });

    const filteredProductsWithAssets = computed(() => {
      return productsWithAssets.value.filter((product) => {
        return product.name
          .toLowerCase()
          .includes(searchForProductsWithAssets.value.toLowerCase());
      });
    });

    const search = ref("");
    const searchForProductsWithAssets = ref("");
    const file = ref(null);
    const productAddModal = ref();
    const assetAddModal = ref();

    let file_name = ref("");

    function setFormData() {
      formData.set("user_id", selectedUser.value.user_id);
      formData.set("text", selectedUser.value.message);
      if (selectedUser.value.image) {
        formData.set("image", selectedUser.value.image);
      }
      if (selectedUser.value.file) {
        formData.set("file", selectedUser.value.file);
      }
    }

    function clearFormData() {
      formData.delete("user_id");
      formData.delete("text");
      formData.delete("image");
      formData.delete("file");
      errorMessage.value = "";
    }

    const config = {
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        emitter.emit("uploadPercent", [
          percentCompleted,
          formData.has("image") || formData.has("file"),
        ]);
      },
    };

    function showAssets(productId) {
      if (productId == null) {
        assetList.value = [];
        selectedAssets.value = [];
        return;
      }

      const selctedIndex = filteredProductsWithAssets.value.findIndex(
        (item) => item.id == productId
      );

      if (selctedIndex < 0) {
        return;
      }

      assetList.value = filteredProductsWithAssets.value[selctedIndex].assets;
    }

    function sendAsset() {
      assetAddModal.value.hide();
      for (let i = 0; i < selectedAssets.value.length; i++) {
        console.log("Loop Working " + i);
        let asset = assetList.value.find(
          (item) => item.id == selectedAssets.value[i]
        );
        if (asset) {
          console.log(asset);
          axios
            .post(
              process.env.VUE_APP_POPIN_URL + "/api/v1/seller/message/asset",
              {
                user_id: selectedUser.value.user_id,
                asset_id: asset.id,
                name: asset.name,
                url: asset.url,
                preview: asset.preview,
              },
              config
            )
            .then((res) => {
              emitter.emit("sellerMsg", res.data);
              console.log("RES", res);
              assetList.value = [];
              selectedAssets.value = [];
            });
        }
      }
    }

    function sentMessage() {
      if (
        selectedUser.value.message ||
        selectedUser.value.file ||
        selectedUser.value.image
      ) {
        setFormData();
        selectedUser.value.message = "";
        selectedUser.value.image = "";
        selectedUser.value.file = "";
        file.value = null;
        file_name.value = "";
        axios
          .post(
            process.env.VUE_APP_POPIN_URL + "/api/v1/seller/message",
            formData,
            config,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            emitter.emit("sellerMsg", res.data);
            clearFormData();
          })
          .catch((error) => {
            if (error.response.status == 422) {
              console.log(error.response.data.message.join(", "));
              errorMessage.value = error.response.data.message.join(", ");
            }
          });
      }
    }
    const handleFileUpload = async () => {
      if (file.value.files[0]["size"] > 40000000) {
        alert("File size should be less than 40MB");
        file.value.value = null;
        return;
      }
      if (
        file.value.files[0]["type"] === "image/jpeg" ||
        file.value.files[0]["type"] === "image/png"
      ) {
        selectedUser.value.image = file.value.files[0];
      } else {
        selectedUser.value.file = file.value.files[0];
      }
      file_name.value = file.value.files[0].name;
    };

    function getProducts() {
      loadingProducts.value = true;
      axios
        .get(process.env.VUE_APP_POPIN_URL + "/api/v1/seller/products")
        .then((res) => {
          products.value = res.data;
          loadingProducts.value = false;
          console.log(products.value);
        });
    }

    function getProductsWithAssets() {
      loadingAssets.value = true;
      axios
        .get(process.env.VUE_APP_POPIN_URL + "/api/v1/seller/products/assets")
        .then((res) => {
          productsWithAssets.value = res.data;
          loadingAssets.value = false;
        });
    }

    function sendProducts() {
      productAddModal.value.hide();
      for (let i = 0; i < selectedProducts.value.length; i++) {
        console.log("Loop Working " + i);
        axios
          .post(
            process.env.VUE_APP_POPIN_URL + "/api/v1/seller/message",
            {
              user_id: selectedUser.value.user_id,
              product_id: selectedProducts.value[i],
            },
            config
          )
          .then((res) => {
            emitter.emit("sellerMsg", res.data);
            console.log("RES", res);
            selectedProducts.value = [];
          });
      }
    }

    return {
      search,
      searchForProductsWithAssets,
      selectedUser,
      sentMessage,
      errorMessage,
      handleFileUpload,
      file,
      file_name,
      products,
      productsWithAssets,
      filteredProducts,
      filteredProductsWithAssets,
      selectedProducts,
      assetList,
      selectedAssets,
      showAssets,
      sendAsset,
      loadingProducts,
      getProducts,
      getProductsWithAssets,
      sendProducts,
      productAddModal,
      assetAddModal,
      loadingAssets,
    };
  },
  watch: {
    userId() {
      this.selectedUser.user_id = this.userId;
    },
  },
  mounted() {
    this.selectedUser.user_id = this.userId;
    this.getProducts();
    this.getProductsWithAssets();
    this.selectedProducts = [];
    this.productAddModal = new Modal(
      document.getElementById("productAddModal")
    );
    this.assetAddModal = new Modal(document.getElementById("assetAddModal"));
  },
  computed() {},
};
</script>
<!-- height: 530px; -->
<style scoped>
.h-80 {
  height: 80px;
  border-radius: 10px;
}

.input-phone {
  padding: 6px 10px !important;
}

.popin-form-control {
  background: rgba(217, 217, 217, 0.3);
  border-radius: 20px;
  color: rgba(55, 55, 55, 0.8);
  border: none;
  outline: none;
  width: 100%;
  height: 43px;
}

.popin-form-control::placeholder {
  font-size: 14px;
  color: rgba(55, 55, 55, 0.5);
}

.input-otp {
  padding: 12px 12px 12px 20px !important;
}

.modal-dialog {
  width: 550px;
  height: 650px;
}

.brs {
  width: 94%;
  margin: 0 auto;
}

textarea:focus {
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.border-top-langth {
  width: 96%;
  margin: 0 auto;
}

#upload-btn-label {
  padding: 0.5rem;
  cursor: pointer;
}

.send-textarea {
  resize: none;
  border-style: none;
  border-color: Transparent;
  overflow: auto;
  height: 60px;
}

.has-file-dot {
  position: absolute;
  left: 10px;
  bottom: 44px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: red;
}
</style>

<style src="../assets/css/scroll.css"></style>
