<template>
  <div class="modal" data-bs-backdrop="static" tabindex="-1" id="exampleModal" ref="incomingConnectionModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content incoming-call-modal-body bg-dark">
        <div class="modal-body">
          <div class="text-align-center ml-4 mr-4">
            <p class="incoming-call-modal-title text-center">
              Call from
              <strong>{{ connectionDetails.userName }}</strong>
            </p>
          </div>
          <div class="text-white text-center user-name">
            {{ connectionDetails.productName }}
          </div>
          <div class="text-center mt-25 mb-25">
            <canvas height="180" ref="cycleTimerRef" width="180" id="cycleTimer2" />
          </div>
          <div class="d-flex mt-30 pt-16 justify-content-evenly accept-callBox">
            <div>
              <span class="call bg-green cursor-pointer rounded-circle" @click.prevent="onAcceptConnection()">
                <i class="bi text-21 bi-telephone text-white"></i>
              </span>
            </div>
            <div>
              <span class="call bg-red cursor-pointer rounded-circle" @click.prevent="onDeclineConnection()">
                <i class="bi text-21 bi-telephone-x text-white"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import axios from "axios";
import { Modal } from "bootstrap";
import emitter from "tiny-emitter/instance";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const router = useRouter();
const modal = ref();
const incomingConnectionModal = ref(null);
let fillNumberCounter = null;
const connectionDetails = ref({
  sellerConnectRequestId: "",
  userName: "",
  userNameFirstletter: "",
  //   userId: "",
  timeOut: "",
  startTime: "",
  productName: "",
  mode: 1,
});

onMounted(() => {
  modal.value = new Modal(incomingConnectionModal.value);
  emitter.on("incomingConnection", (data) => {
    incomingConnection(data);
  });
});

function modalClose() {
  modal.value.hide();
  document.body.classList.remove("modal-open");
  var elements = document.getElementsByClassName("modal-backdrop");
  for (var i = 0; i < elements.length; i++) {
    elements[i].remove();
  }
  clearTimeout(fillNumberCounter);
}

function incomingConnection(data) {
  console.log(data);
  var counter = document.getElementById("cycleTimer2").getContext("2d");
  createTimer(counter, data.timeout);
  connectionDetails.value.sellerConnectRequestId = data.connect_request_id;
  connectionDetails.value.productName = data.artifact;
  connectionDetails.value.userName = data.name;
  connectionDetails.value.timeOut = data.timeout;
  connectionDetails.value.startTime = data.start;
  connectionDetails.value.mode = data.mode;

  var str = connectionDetails.value.userName;
  var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
  var acronym = matches.join("");
  connectionDetails.value.userNameFirstletter = acronym;

  modal.value.show();
  modalCloseOnTimeOut(data.timeout);
}

function modalCloseOnTimeOut(timeout) {
  setTimeout(() => {
    emitter.emit("notSoundEnd");
    modalClose();
  }, timeout * 1000);
}

function createTimer(counter, time) {
  var no = time;
  var pointToFill = 4.72;
  var cw = counter.canvas.width;
  var ch = counter.canvas.height;
  var diff;

  function fillCounter() {
    diff = (no / time) * Math.PI * 2 * 10;
    counter.clearRect(0, 0, cw, ch);
    counter.lineWidth = 10;
    counter.fillStyle = "#ffffff";
    counter.strokeStyle = " #6f42c1";
    counter.textAlign = "center";
    counter.font = "35px monospace";
    counter.fillText(no, 100, 110);
    counter.beginPath();
    counter.arc(100, 100, 50, pointToFill, diff / 10 + pointToFill);
    counter.stroke();

    if (no == 0) {
      clearTimeout(fillNumberCounter);
    }
    no--;
  }

  fillNumberCounter = setInterval(fillCounter, 1000);
}

function onAcceptConnection() {
  emitter.emit("notSoundEnd");
  modalClose();

  let connectionURL =
    process.env.VUE_APP_POPIN_URL + "/api/v1/seller/connection/accept";
  let requestBody = {
    connect_request_component_id:
      connectionDetails.value.sellerConnectRequestId,
  };
  if (connectionDetails.value.mode == 2) {
    connectionURL =
      process.env.VUE_APP_POPIN_URL + "/api/v1/seller/participant/accept";
    requestBody = {
      call_participant_id: connectionDetails.value.sellerConnectRequestId,
    };
  }

  console.log(connectionURL);
  axios.post(connectionURL, requestBody).then((res) => {
    if (res.data.status == 1) {
      emitter.emit("notSoundEnd");
      console.log("ACC1");
      console.log(res.data);
      const callDetails = {
        callId: res.data.connect_request_id,
        userId: res.data.user_id,
        name: res.data.name,
        artifact: res.data.artifact,
        accessToken: res.data.access_token,
        sellerId: res.data.seller_id,
        userName: res.data.user_name,
        room: res.data.room,
        mode: connectionDetails.value.mode,
        websocket: res.data.websocket,
        status: res.data.status,
        createdAt: res.data.created_at,
      };
      console.log("ACC2");
      store.dispatch("call/acceptCall", callDetails).then(() => {
        console.log("ACC3");
        router.push({ name: "room" });
      });
    }
  });
}

function onDeclineConnection() {
  emitter.emit("notSoundEnd");
  modalClose();
  axios.post(
    process.env.VUE_APP_POPIN_URL + "/api/v1/seller/connection/reject",
    {
      connect_request_component_id:
        connectionDetails.value.sellerConnectRequestId,
    }
  );
}
</script>

<style scoped>
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .modal-dialog {
    left: 12%;
  }

  .incoming-call-modal-body {
    max-width: 331px !important;
  }

  .incoming-call-modal-title {
    font-size: 20px !important;
    margin: 0 0 12px 0 !important;
  }

  .mt-30,
  .mt-25 {
    margin-top: 20px !important;
  }

  .mb-25 {
    margin-bottom: 20px !important;
  }

  canvas {
    width: 170px !important;
    height: 170px !important;
  }

  .user-name {
    font-size: 13px !important;
  }

  .text-14 {
    font-size: 11px !important;
  }

  .pad {
    padding: 0px 0px 5px 20px !important;
  }

  .mt-20 {
    margin-top: 10px !important;
  }

  .call {
    padding: 20px !important;
  }

  .accept-callBox {
    margin-bottom: 30px !important;
  }

  .mb-12 {
    margin-bottom: 10px !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1435px) {
  .accept-callBox {
    margin-bottom: 30px !important;
  }
}

@media screen and (min-width: 1025px) {
  .modal-dialog {
    left: 8%;
  }

  .incoming-call-modal-body {
    width: 331px !important;
  }

  .incoming-call-modal-title {
    font-size: 20px !important;
    margin: 0 0 12px 0 !important;
  }

  .mt-30,
  .mt-25 {
    margin-top: 20px !important;
  }

  .mb-25 {
    margin-bottom: 20px !important;
  }

  canvas {
    width: 170px !important;
    height: 170px !important;
  }

  .user-name {
    font-size: 13px !important;
  }

  .text-14 {
    font-size: 11px !important;
  }

  .pad {
    padding: 0px 0px 5px 20px !important;
  }

  .mt-20 {
    margin-top: 10px !important;
  }

  .call {
    padding: 20px !important;
  }

  .accept-callBox {
    margin-bottom: 20px !important;
  }

  .mb-12 {
    margin-bottom: 10px !important;
  }
}

.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

@media screen and (min-width: 1284px) {
  .modal-dialog {
    left: 6%;
  }

  .incoming-call-modal-body {
    width: 402px !important;
    /* height: 500px; */
    padding: 40px !important;
  }

  .incoming-call-modal-title {
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 31px;
    color: #ffffff;
    margin: 25px 0px;
  }
}

.bg-red {
  background: #ff000e;
}

.cycleTimerBox {
  display: inline-block;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #1783f0;
  float: right;
}

.bg-green {
  background: #1bc100;
}

.call {
  padding: 27px;
}

.pad {
  padding: 7px 0px 6px 20px;
  border-radius: 50px;
}

.btn {
  border-radius: 50% !important;
  height: 80px;
  width: 80px;
}

.modal-content {
  border-radius: 30px !important;
}

/* .incoming-call-modal-body {
  background: #000;
  width: 552px;
  height: 700px;
  padding: 40px;
}
.incoming-call-modal-title {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  color: #ffffff;
  margin: 25px 0px 50px 0px;
} */
.incoming-call-accept-btn {
  width: 70px;
  height: 70px;
  font-size: 24px;
}

.incoming-call-reject-btn {
  width: 70px;
  height: 70px;
  font-size: 24px;
}

.incoming-call-name-circle {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #506cff;
  border-radius: 50px;
  font-size: 38px;
  color: #fff;
  margin: 10px auto;
}

.popin-form-control {
  background: #f7f7f7;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: rgb(0, 0, 0);
  border: none;
  outline: none;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
}

.popin-form-control::placeholder {
  font-size: 16px;
}

label {
  color: #fff;
}
</style>
